<template>
    <div>
        <div class="lead-search-area">
            <div class="row">
                <div class="col-sm-6 col-md-6 col-lg-2">
                    <div class="lead-search">
                        <a class="refresh btn-block btn" data-bs-toggle="modal" data-bs-target="#registerReader"  href="javascript:;">Register Card Reader</a>
                    </div>
                </div>
            </div>
        </div>

        <!-- Register Reader Modal -->
        <div class="modal fade" id="registerReader" tabindex="-1" aria-labelledby="registerReader" aria-hidden="true">
            <div class="modal-dialog " role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <h3>Register Card Reader 1</h3>
                    </div>
                    <div class="modal-body">
                            <form class="test" method="post">
                                <div class="form-group">
                                    <label class="fw-bold">Registration code
                                        <fa icon="info-circle" class="ml-1 text-black-50" data-bs-toggle="tooltip" title="Enter the registration code here, these code appears shortly after you have connected the device to the internet"></fa>

                                    </label>
                                    <input type="text" class="form-control mt-1"  v-model="readerInfo.reg_code" placeholder="apple-graph-orange" >
                                </div>
                                <div class="form-group" >
                                    <label class="fw-bold">Reader label
                                        <fa icon="info-circle" class="ml-1 text-black-50" data-bs-toggle="tooltip" title="Enter a name you can easily remember to identify the reader."></fa>
                                    </label>
                                    <input type="text" class="form-control mt-1"  v-model="readerInfo.label" placeholder="optional" >
                                </div>
                            </form>
                    </div>
                    <div class="modal-footer">
                        <a href="#" data-bs-dismiss="modal" aria-label="Cancel" class="add-btn">Cancel</a>
                        <a href="#" v-on:click.prevent="submitReader" class="rem-btn">Save</a>
                    </div>
                </div>
            </div>
        </div>

        
        <div class="leads-table-area addcustomer-table">
        <table id="reader_table" class="display">
            <thead>
                <tr>
                    <th>Reader ID</th>
                    <th>Label</th>
                    <th>Serial number</th>
                    <th>Model</th>
                    <th>Status</th>
                    <th>Action</th>
                </tr>
            </thead>
            <tbody>
            </tbody>
        </table>
    </div>


    </div>  




</template>

<script>
// import $ from 'jquery'
import axios from 'axios'
import { useToast } from "vue-toastification";
import $ from "jquery";
import "datatables.net";
import "datatables.net-responsive";
import "datatables.net-dt/css/jquery.dataTables.min.css";
import "datatables.net-responsive-dt/css/responsive.dataTables.min.css";
export default {
    setup () {
        const toast = useToast();
        return {toast}
    },
    data(){
		return{
            isStripeConnected:false,
            StripeAccountStatus:"",
            registrationCode: '',
            readerInfo:{
                reg_code:"",
                label:""
            }
    
		}
    },
    created() {
        this.checkStripeConnectAccount()
    },
    methods: {
        submitReader() {
            // Handle the logic to submit the registration code
           
            let thiss = this
            axios.post('add_reader',this.readerInfo).then(function (response) {
                if (response.data.status == 'success') {
                    thiss.readerInfo.reg_code = "";
                    thiss.readerInfo.label = "";

                    $("#registerReader").modal("hide");
                    $("#reader_table").dataTable().fnDraw();
                    thiss.toast.success(response.data.message);
                }else if (response.data.status == 'login') {
                    thiss.$storage.removeStorageSync("user")
                    thiss.$storage.removeStorageSync("token")
                    thiss.$storage.removeStorageSync("business")
                    thiss.$router.push({ name: 'Home'})
                }
            })
            .catch(error => {
                if (error.response) {
                    if (error.response.status == 422) {
                        this.toast.error(error.response.data.message);
                    }else if (error.response.status == 401) {
                        this.toast.error(error.response.data.message,{position: "top-right"});
                    }
                }
            });

            // You can implement the logic to send the registration code to your server here
        },
        checkStripeConnectAccount(){
            let thiss = this
            axios.get('check_stripe_account').then(function (response) {
                if (response.data.status == 'success') {
                    if(response.data.data.connected > 0){
                        thiss.isStripeConnected = true;
                        thiss.StripeAccountStatus = response.data.data.status;
                    }

                }
            })
            .catch(error => {
                if (error.response) {
                    console.log(error.response.data.message)
                }
            });
        },
        removeReader(id){
            let thiss = this
           
            this.$swal({
                title: 'Are you sure?',
                text: "Reader will be removed from your account and all information about the device will be removed. The device can be re-registered to this account or registered to another after being removed.",
                showCancelButton: true,
                confirmButtonText: 'Yes',
                cancelButtonText: 'No',
                reverseButtons: false,
                customClass: {
                    confirmButton: 'rem-btn mr-3',
                    cancelButton: 'add-btn'
                },
                buttonsStyling: false
            }).then((result) => {
                if (result.isConfirmed) {
                    axios.post('remove_reader',{t_id:id}).then(function (response) {
                        if (response.data.status == 'success') {
                            
                            $("#reader_table").dataTable().fnDraw();
                            thiss.toast.success(response.data.message);
                            
                        }
                    })
                    .catch(error => {
                        if (error.response) {
                            if (error.response.status == 422) {
                                thiss.toast.error(error.response.data.message);
                            }else if (error.response.status == 401 && error.response.statusText == "Unauthorized") {
                                thiss.$storage.removeStorageSync("user")
                                thiss.$storage.removeStorageSync("token")
                                thiss.$storage.removeStorageSync("business")
                                thiss.$router.push({ name: 'Home'})
                            }else if (error.response.status == 401) {
                                thiss.toast.error(error.response.data.message,{position: "top-right"});
                            }else{
                                thiss.toast.error(error.response.data.message);
                            }
                        }
                    });
                }
            })

        },
       
    },
    mounted() {
        this.code = this.$route.query.code;
        let thiss = this;
        $("#reader_table")
        .dataTable({
            //order: [[7, "desc"]],
            responsive: true,
            fixedHeader: true,
            processing: true,
            serverSide: true,
            ajax: {
                url: axios.defaults.baseURL + "dt_reader_list",
                type: "POST",
                data: function (d) {
                    d.business_id = thiss.$storage.getStorageSync('business').active_business.id
                },
                headers: {
                    Authorization: axios.defaults.headers.common['Authorization'],
                }
            },
            searching: false,
            drawCallback: function (response) {
                //alert( 'Table redrawn '+new Date() );
                thiss.stats = response.json.stats
                console.log(response.json.stats)
            },
            columnDefs: [
                {
                    targets: [2, 4],
                    orderable: false,
                },
            ]
        });
        $("tbody").on("click", ".change-status", function (e) {
            e.preventDefault();
            let t_id = $(this).data("id");
            thiss.removeReader(t_id)
        });
    },

}
</script>
<style scoped>
@import "../../../assets/css/dashboard/dataTables.css";
</style>
<style scoped>

.lead-search .date-controll{
    background-color:#fff !important;
    padding-left: 30px;
}
.dp__main{
    width: 100%;
}
.dp__input_icon{
    left: 13px;
}
.dp__clear_icon{
    right: -15px;
}
.counter-num h4{
    font-size: 25px;
}
.modal-header img{
    filter: brightness(0) invert(1);
}
.modal-header .close{
    background: black;
}
.modalText{
    color: #3f3f3f
}
.balance{
    font-size:30px;
    color:#078c26 !important
}
.note{
    background:#F8f9fA;
    font-size: 11px;
}
.note a{
    font-size: 11px;
    text-decoration: none;
    font-weight: bold;
}
.note a:hover{
    color: #6e317a !important;
}

.main_box{
    width: 100%;
    align-items: center;
    background: #FFFFFF;
    box-shadow: 0px 4px 8px rgb(49 81 161 / 8%);
    border-radius: 8px;
    padding: 15px 30px;
    margin-bottom: 25px;
}
.stripe-error{
    color: #eb6663;


}
.stripe-success{
    color: green;


}
.connect-stripe-img{
    width: 190px !important;
}


.registration-form {
    background: linear-gradient(135deg, #3498db, #8e44ad);
    border-radius: 10px;
    color: #fff;
}

.registration-form h2 {
    font-size: 24px;
    font-weight: bold;
}

.registration-form form {
    max-width: 400px;
    margin: auto;
}


.registration-form input,
.registration-form button {
    margin-top: 10px;
}

@media (max-width: 992px) {
  .container {
    width: 100%;
  }
}
@media (max-width: 768px) {
  .container {
    width: 100%;
    max-width: none;
  }
}
</style>