<template>
    <AppContainer>
        <stripeReader/>
    </AppContainer>
</template>

<script>
import AppContainer from '@/components/containers/DashboardContainer.vue'
 import stripeReader from '@/components/dashboard/stripe/stripeReader.vue'

export default {
  components: {
    AppContainer,
     stripeReader
  }
}
</script>